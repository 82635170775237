@import '../../common/sass/variables';

@keyframes spinner-icon {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.spinner-icon {
    div {
        position: absolute;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        animation: spinner-icon 1s linear infinite;
        box-sizing: content-box;
    }

    &--primary div {
        border-color: $primary !important;
        border-top-color: transparent !important;
    }

    &--secondary div {
        border-color: $secondary !important;
        border-top-color: transparent !important;
    }

    &--xs {
        div {
            width: 12px;
            height: 12px;
            top: 10px;
            left: 10px;
            border: 2px solid $white;
            border-top-color: transparent;
        }
    }
    &--sm {
        div {
            width: 24px;
            height: 24px;
            top: 23px;
            left: 23px;
            border: 3px solid $white;
            border-top-color: transparent;
        }
    }
    &--md {
        div {
            width: 48px;
            height: 48px;
            top: 40px;
            left: 40px;
            border: 6px solid $white;
            border-top-color: transparent;
        }
    }
    &--lg {
        div {
            width: 64px;
            height: 64px;
            top: 50px;
            left: 50px;
            border: 8px solid $white;
            border-top-color: transparent;
        }
    }
    &--xl {
        div {
            width: 96px;
            height: 96px;
            top: 70px;
            left: 70px;
            border: 10px solid $white;
            border-top-color: transparent;
        }
    }
}

.spinner-rolling {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;

    &--xs {
        width: 20px;
        height: 20px;
    }
    &--sm {
        width: 46px;
        height: 46px;
    }
    &--md {
        width: 80px;
        height: 80px;
    }
    &--lg {
        width: 100px;
        height: 100px;
    }
    &--xl {
        width: 140px;
        height: 140px;
    }
}

.spinner-icon {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
}
