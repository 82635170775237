@import './common/sass/variables';
@import './common/sass/font.scss';

@import '~react-toastify/dist/ReactToastify.css';

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: $font-family;
}

/* Mobile First: */
/* For mobile phones: */
.row {
    &::after {
        content: '';
        clear: both;
        display: block;
    }
}

[class*='col-'] {
    float: left;
}

[class*='col-'] {
    width: 100%;
}

@media only screen and (min-width: $break-point-sm) {
    /* For tablet: */
    .col-sm-1 {
        width: 8.33%;
    }
    .col-sm-2 {
        width: 16.66%;
    }
    .col-sm-3 {
        width: 25%;
    }
    .col-sm-4 {
        width: 33.33%;
    }
    .col-sm-5 {
        width: 41.66%;
    }
    .col-sm-6 {
        width: 50%;
    }
    .col-sm-7 {
        width: 58.33%;
    }
    .col-sm-8 {
        width: 66.66%;
    }
    .col-sm-9 {
        width: 75%;
    }
    .col-sm-10 {
        width: 83.33%;
    }
    .col-sm-11 {
        width: 91.66%;
    }
    .col-sm-12 {
        width: 100%;
    }
}

@media only screen and (min-width: $break-point-md) {
    /* For tablet: */
    .col-1 {
        width: 8.33%;
    }
    .col-2 {
        width: 16.66%;
    }
    .col-3 {
        width: 25%;
    }
    .col-4 {
        width: 33.33%;
    }
    .col-5 {
        width: 41.66%;
    }
    .col-6 {
        width: 50%;
    }
    .col-7 {
        width: 58.33%;
    }
    .col-8 {
        width: 66.66%;
    }
    .col-9 {
        width: 75%;
    }
    .col-10 {
        width: 83.33%;
    }
    .col-11 {
        width: 91.66%;
    }
    .col-12 {
        width: 100%;
    }
}

@media only screen and (min-width: $break-point-lg) {
    /* For tablet: */
    .col-lg-1 {
        width: 8.33%;
    }
    .col-lg-2 {
        width: 16.66%;
    }
    .col-lg-3 {
        width: 25%;
    }
    .col-lg-4 {
        width: 33.33%;
    }
    .col-lg-5 {
        width: 41.66%;
    }
    .col-lg-6 {
        width: 50%;
    }
    .col-lg-7 {
        width: 58.33%;
    }
    .col-lg-8 {
        width: 66.66%;
    }
    .col-lg-9 {
        width: 75%;
    }
    .col-lg-10 {
        width: 83.33%;
    }
    .col-lg-11 {
        width: 91.66%;
    }
    .col-lg-12 {
        width: 100%;
    }
}

@media only screen and (min-width: $break-point-xl) {
    /* For tablet: */
    .col-xl-1 {
        width: 8.33%;
    }
    .col-xl-2 {
        width: 16.66%;
    }
    .col-xl-3 {
        width: 25%;
    }
    .col-xl-4 {
        width: 33.33%;
    }
    .col-xl-5 {
        width: 41.66%;
    }
    .col-xl-6 {
        width: 50%;
    }
    .col-xl-7 {
        width: 58.33%;
    }
    .col-xl-8 {
        width: 66.66%;
    }
    .col-xl-9 {
        width: 75%;
    }
    .col-xl-10 {
        width: 83.33%;
    }
    .col-xl-11 {
        width: 91.66%;
    }
    .col-xl-12 {
        width: 100%;
    }
}
