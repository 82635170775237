@import './common/sass/variables';

.App {
    background-color: $gray-100;
}
h1 {
    font-size: 60px;
    line-height: 72px;
}
h2 {
    font-size: 48px;
    line-height: 56px;
}
h3 {
    font-size: 32px;
    line-height: 48px;
}
h4 {
    font-size: 28px;
    line-height: 36px;
}
h5 {
    font-size: 20px;
    line-height: 28px;
}
h6 {
    font-size: 14px;
    line-height: 20px;
}

.custom-scrollbar {
    &::-webkit-scrollbar {
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background-color: $bg-scroller;
        box-sizing: border-box;
        border: 1px solid $gray-400;
    }

    &--round {
        &::-webkit-scrollbar-track {
            border-radius: 0px 4px 4px 0px;
        }
    }

    &::-webkit-scrollbar-thumb {
        width: 7px !important;
        background: $gray-secondary;
        border-radius: 10px;
        display: block;
        border: 4px solid transparent;
        background-clip: content-box;
    }
}

.loadable__content {
    height: calc(100vh - 244px);
}

.cursor-default {
    cursor: default !important;
}
