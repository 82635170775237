@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Regular.ttf') format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Thin.ttf') format('truetype');
    font-weight: 100;
}
@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-ExtraLight.ttf') format('truetype');
    font-weight: 200;
}
@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Light.ttf') format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Medium.ttf') format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-SemiBold.ttf') format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Bold.ttf') format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}
@font-face {
    font-family: 'Barlow';
    src: url('../assets/fonts/Barlow-Black.ttf') format('truetype');
    font-weight: 900;
}
