@import '../../common/sass/variables';

.loading {
    &__desc {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: $white;
    }
}
