/* variables color */
$primary-normal-hover: #{mix(#0e4486, #000, 85%)};
$primary-pale-text: #1f47cd;
$primary-pale-hover: #{mix(#e3f4ff, #0e4486, 85%)};
$primary-danger-hover: #{mix(#fee2e2, #b91c1c, 85%)};
$primary-light-hover: #{mix(#f5f5f5, #f0f0f0, 85%)};
$danger-hover: #{mix(#fee2e2, #f66868, 85%)};
$main-shadow: rgba(198, 198, 198, 0.32);
$sub-header-shadow: rgba(51, 51, 51, 0.08);
$pale-shadow: #7aa5ca;
$pale-danger: #fee2e2;
$input-focus-shadow: rgba(58, 122, 177, 0.4);
$white: #ffffff;
$black: #000000;
$text-dark: #333333;
$bg-primary: #0b4586;
$bg-primary-gadient: #146ba7;
$boder-pale-color: #bcd2e4;
$bg-scroller: #fcfcfc;
$gray-secondary: #c4c4c4;
$light-pink: #c36eaa;
$dark-pink: #c04f9e;

/* primary color */
$primary-dark: #1f47cd;
$primary: #0e4486;
$primary-muted: #1dadff;
$primary-light: #bbe2ff;
$primary-pale: #dee9f2;
$primary-normal: #3a7ab1;
$primary-bg-text: #06154f;
$secondary-bg-btn: #dee9f2;

/* Secondary color */
$secondary-dark: #4909c6;
$secondary: #7424db;
$secondary-muted: #a16ee7;
$secondary-light: #d7c2f4;
$secondary-pale: #f0e6fb;

/* Semantic color */
$success: #065f46;
$success-secondary: #4a9631;
$info: #1e40af;
$warning: #92400e;
$warning-secondary: #f59e0b;
$danger: #b91c1c;

/*Background semantic color*/
$bg-success: #d1fae5;
$bg-success-secondary: #dbf0cb;
$bg-info: #dbeafe;
$bg-warning: #fef3c7;
$bg-danger: #fee2e2;
$bg-monochrome: #9e9e9e;

/* Monochrome color */
$gray-1000: #f7f7f7;
$gray-900: #212121;
$gray-800: #4a4a4a;
$gray-700: #757575;
$gray-600: #898989;
$gray-500: #9e9e9e;
$gray-400: #e0e0e0;
$gray-300: #f0f0f0;
$gray-200: #f5f5f5;
$gray-100: #fafafa;
$white: #fff;
$black: #000;

/* Breakpoints */
$break-point-xs: 320px;
$break-point-sm: 576px;
$break-point-md: 768px;
$break-point-lg: 1024px;
$break-point-xl: 1200px;
$break-point-xxl: 1344px;
$break-point-xxxl: 1600px;

$font-family: 'Barlow';
